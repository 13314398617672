import * as React from "react";
import { gql } from 'apollo-boost'
import '../App.css';
import { MatrikkelAddresses, QueryResponse } from "../Interfaces/QueryResponse";
import { ChangeEvent, useState } from "react";
import { ApolloConsumer } from "@apollo/react-hooks";
import { Matrikkel } from "../Interfaces/Matrikkel";

export interface AddressFinderProps {
    setAddress: (address: Matrikkel) => void
}

export interface AddressFinderState {
    postplace: string;
    zipcode?: number;
    addressInput: string;
    addresses: Matrikkel[];
    address?: Matrikkel;
}

const ADDRESSES = gql`
    fragment allAddresses on MatrikkelType {
        id
        postNr
        poststed
        gatenavn
        husnummer
        bokstav
        etasje
        boligNr
        gnr
        bnr
        xKoord
        yKoord
    }`;

const GET_ADDRESSES_WITH_STREET = gql`
    query getAddresses($address: String!, $zip: String!) {
        addresses(address: $address, zipCode: $zip) {
            ...allAddresses
        }
    }
    ${ADDRESSES}
`;

const GET_ADDRESSES_GNR_BNR = gql`
    query getAddresses($gnr: Int!, $bnr: Int!, $zip: String!) {
        addresses(gnr: $gnr, bnr: $bnr, zipCode: $zip) {
            ...allAddresses
        }
    }
    ${ADDRESSES}
`;

interface BringApiResponse {
    result: string;
    valid: boolean;
    postalCodeType: string;
    potet: string;
    graut: boolean;
}

async function getPostplace(zipcode: number): Promise<string> {
    const url = `https://api.bring.com/shippingguide/api/postalCode.json?pnr=${zipcode}&clientUrl=tafjord.no`;
    const response = await fetch(url, {method: 'GET'});
    const data: BringApiResponse = await response.json();
    return data.result;
}

export default function AddressForm(props: AddressFinderProps) {
    const [selectedZipCode, setSelectedZipCode] = useState(0);
    const [selectedCity, setSelectedCity] = useState("");
    const [addresses, setAddresses] = useState<Matrikkel[]>([]);
    const [searchWithAddress, setSearchWithAddress] = useState(true);
    const [selectedGnr, setSelectedGnr] = useState<string>("");

    return (
        <ApolloConsumer>
            {(client: any) => (
                <div className="w-100 byAddress">
                    <h2 className="visuallyhidden">Finn adresse</h2>
                    <div className="addressHeader">
                        <p className="h3_tags">Jeg ønsker å bestille til denne adressen: </p>
                        <p className="searchSwap" onClick={() => {
                            setAddresses([]);
                            setSearchWithAddress(!searchWithAddress)
                        }}>
                            {searchWithAddress ? "Søk med gårds- og bruksnummer" : "Søk med gatenavn"}
                        </p>
                    </div>
                    <div>
                        <label htmlFor="zipcode">Postnummer</label>
                        <input name="zipcode" id="zipcode" placeholder={"Postnummer"} className="inputfield zipcode"
                               type="text"
                               onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                                   const data = event.target.value;
                                   const zip = parseInt(data);
                                   isNaN(zip) ? setSelectedZipCode(0) : setSelectedZipCode(zip);
                                   if (data.length !== 4) {
                                       setSelectedCity("");
                                       return;
                                   }
                                   setSelectedCity(await getPostplace(zip))
                               }} value={selectedZipCode !== 0 ? selectedZipCode : ""}/>
                        <b>{selectedCity}</b>
                    </div>

                    <div>
                        {
                            searchWithAddress ?
                                <div>
                                    <label htmlFor="address">Gateadresse</label>
                                    <input name="addressInput" id="address" placeholder={"Gateadresse"}
                                           className="inputfield address" aria-owns="results" aria-autocomplete="both"
                                           type="text"
                                           onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                                               if (event.target.value.length < 4) return;

                                               const response: QueryResponse<MatrikkelAddresses> = await client.query({
                                                   query: GET_ADDRESSES_WITH_STREET,
                                                   variables: {
                                                       address: event.target.value,
                                                       zip: selectedZipCode
                                                   }
                                               });
                                               setAddresses(response.data.addresses);
                                           }}
                                    />
                                </div>
                                :
                                <div>
                                    <label htmlFor="gnr">Gårdsnummer</label>
                                    <input name="addressInput" id="gnr" placeholder={"Gnr"}
                                           className="inputfield gnrInput" type="text"
                                           onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                                               setSelectedGnr(event.target.value);
                                           }}
                                    />
                                    <label htmlFor="bnr">Bruksnummer</label>
                                    <input name="addressInput" id="bnr" placeholder={"Bnr"}
                                           className="inputfield bnrInput" type="text"
                                           onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                                               const response: QueryResponse<MatrikkelAddresses> = await client.query({
                                                   query: GET_ADDRESSES_GNR_BNR,
                                                   variables: {
                                                       gnr: selectedGnr,
                                                       bnr: event.target.value,
                                                       zip: selectedZipCode
                                                   }
                                               });
                                               setAddresses(response.data.addresses);
                                           }}
                                    />
                                </div>
                        }
                    </div>

                    <div className="addressListContainer" id="results">
                        {addresses.map((address) => {
                            return (
                                <div className="addressContainer"
                                     key={address.id}
                                     onClick={() => {
                                         props.setAddress(address);
                                     }}
                                >
                                    {address.gatenavn} {address.husnummer}{address.bokstav} {address.boligNr}, {address.postNr} {address.poststed} {address.bnr !== null ? "(" + address.gnr + "/" + address.bnr + ")" : null}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </ApolloConsumer>
    );
}
