import * as React from "react";
import '../App.css';
import {ProductPart} from "./ProductPart";

import {gql} from 'apollo-boost'
import {ApolloClient} from 'apollo-client';
import {HttpLink} from 'apollo-link-http';
import {InMemoryCache} from "apollo-cache-inmemory"
import {Product} from "../Interfaces/Product";
import {Address} from "../Interfaces/Address";
import {Organization} from "../Interfaces/Organization";
import {Organizations, QueryResponse} from "../Interfaces/QueryResponse";
import {ChangeEvent} from "react";
import moment = require("moment");

const FORM_API_ROOT = "https://salgapi.tafjord.no";

const client = new ApolloClient({
    link: new HttpLink({uri: "https://salg.tafjord.no/graphql/public/"}),
    cache: new InMemoryCache()
});

interface OrderSummaryProps {
    product: Product;
    setcustomer: (name: string, surname: string, birthday: Date, cellphone: number, email: string) => void;
    prevPage: () => void;
    nextPage: (posted?: boolean, message?: string) => void;
    address: Address;
    code?: string;
}

interface OrderSummaryState {
    organizations: Organization[];
    firstnameError: boolean;
    lastnameError: boolean;
    bdateError: boolean;
    phoneError: boolean;
    emailError: boolean;
    verifyError: boolean;
    hasPerson: boolean;
    organization?: number;
    linkOrganization?: boolean;
    promoter?: number;
    firstname?: string;
    lastname?: string;
    birthdate?: string;
    phone?: string;
    email?: string;
}

export default class OrderSummary extends React.Component<OrderSummaryProps, OrderSummaryState> {
    constructor(props: OrderSummaryProps) {
        super(props);

        this.state = {
            birthdate: undefined,
            email: undefined,
            firstname: undefined,
            lastname: undefined,
            phone: undefined,
            organizations: [],
            firstnameError: false,
            lastnameError: false,
            bdateError: false,
            phoneError: false,
            emailError: false,
            verifyError: false,
            hasPerson: false,
            organization: undefined,
            promoter: undefined,
            linkOrganization: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.setOrg = this.setOrg.bind(this);
    }


    componentDidMount() {
        this.setState({hasPerson: this.props.address.personFk !== undefined});

        client.query({
            query: gql`{
                allOrganizations {
                    edges {
                        node {
                            pk
                            name
                        }
                    }
                }
            }`
        })
        .then((res: QueryResponse<Organizations>) => {
            this.setState({organizations: res.data.allOrganizations.edges.map(e => e.node)})
            this.getPromoter();
        })
        .catch(() => {
        });

    }

    setOrg(event: ChangeEvent<HTMLSelectElement>) {
        const orgId = +event.target.value;
        this.setState({organization: isNaN(orgId) ? undefined : orgId});
    }

    handleChange(event: ChangeEvent<HTMLInputElement>) {
        let data = event.target.value;
        const i = {[event.target.name]: data} as unknown as Pick<OrderSummaryState, keyof OrderSummaryState>;
        this.setState(i);
    }

    getPromoter() {
        let urlData = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

        if (this.state.organizations) {
            this.state.organizations.map((org) => {
                let translatedOrgName = org.name;
                translatedOrgName = translatedOrgName.toLowerCase().trimRight().replace(/\u00E6/gi, "ae");
                translatedOrgName = translatedOrgName.toLowerCase().trimRight().replace(/\u00F8/gi, "oe");
                translatedOrgName = translatedOrgName.toLowerCase().trimRight().replace(/\u00E5/gi, "aa");
                translatedOrgName = translatedOrgName.toLowerCase().trimRight().replace(/\s+/g, "-");

                if (translatedOrgName === urlData.toLowerCase()) {
                    this.setState({promoter: org.pk})
                }
            });
        }
    }

    validate() {
        let re = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;


        if (this.state.hasPerson) {
            const checkbox = document.querySelector<HTMLInputElement>("#verify");
            if (checkbox && checkbox.checked) {
                this.setState({verifyError: false}, this.sendOrder);
            } else {
                this.setState({verifyError: true});
            }
            return;
        }

        const checkbox = document.querySelector<HTMLInputElement>("#verify");
        if (checkbox && checkbox.checked) {
            this.setState({verifyError: false});
        } else {
            this.setState({verifyError: true});
        }

        let now = moment();
        let duration = moment.duration(now.diff(this.state.birthdate));
        let years = duration.asYears();

        if (years > 18) {
            this.setState({bdateError: false});
        } else {
            this.setState({bdateError: true})
        }

        if (this.state.firstname !== undefined && /^[a-zA-ZæøåÆØÅ ]+/.test(this.state.firstname)) {
            this.setState({firstnameError: false});
        } else {
            this.setState({firstnameError: true});
        }

        if (this.state.lastname !== undefined && /^[a-zA-ZæøåÆØÅ ]+/.test(this.state.lastname)) {
            this.setState({lastnameError: false});
        } else {
            this.setState({lastnameError: true});
        }

        if (this.state.phone !== undefined && /^\d{8}$/.test(this.state.phone)) {
            this.setState({phoneError: false});
        } else {
            this.setState({phoneError: true});
        }

        if (this.state.email !== undefined && re.test(this.state.email)) {
            this.setState({emailError: false}, this.sendOrder);
        } else {
            this.setState({emailError: true});
        }
    }

    async sendOrder() {
        let response;
        let responseCode;

        if (this.state.hasPerson && !this.state.verifyError) {
            try {
                const res = await fetch(`${FORM_API_ROOT}/order/new/existing_customer/`, {
                    method: 'post',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        'addressId': this.props.address.pk!,
                        'personId': this.props.address.personFk.pk,
                        'organization': isNaN(this.state.organization as number) || this.state.organization === 0 ? null : this.state.organization,
                        'productIds': [this.props.product.pk],
                        'offerCode': this.props.code,
                    })
                });
                console.log("(!) Api response " + res);
                responseCode = res.status;
                response = await res.json();
            } catch (e) {
                console.log("(!) Api error " + e);
            }

        } else if (!this.state.firstnameError && !this.state.lastnameError && !this.state.bdateError && !this.state.phoneError && !this.state.emailError && !this.state.verifyError) {

            let birthdate = moment(this.state.birthdate);

            try {
                const res = await fetch(`${FORM_API_ROOT}/order/new/`, {
                    method: 'post',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        'postNr': this.props.address.postnum,
                        'gatenavn': this.props.address.street_name,
                        'husnummer': this.props.address.house_number,
                        'bokstav': this.props.address.house_letter,
                        'boligNr': this.props.address.unit,
                        'firstname': this.state.firstname,
                        'lastname': this.state.lastname,
                        'birthdate': birthdate.format("YYYY-MM-DD"),
                        'phone': this.state.phone,
                        'email': this.state.email,
                        'organization': isNaN(this.state.organization as number) || this.state.organization === 0 ? null : this.state.organization,
                        'promoter': isNaN(this.state.promoter as number) || this.state.promoter === 0 ? null : this.state.promoter,
                        'productIds': [this.props.product.pk]
                    })
                });
                responseCode = res.status;
                response = await res.json();
            } catch (e) {
                console.log("(!) Api error " + e);
            }
        }

        if (typeof response !== 'undefined') {
            if (responseCode === 201) {
                this.props.nextPage(true);
            } else if (response.errors.some((e: any) => e.errorCode === 1)) {
                this.props.nextPage(false, "Dette produktet kan ikke bestilles igjen. Dersom du har spørsmål om dette vennligst ta kontakt med kundeservice.")
            } else if (response.errors.some((e: any) => e.errorCode === 2)) {
                this.props.nextPage(false, "Denne adressen har allerede en ordre som er under behandling. Vennligst prøv igjen senere eller ta kontakt med kundeservice.")
            } else if (response.errors.some((e: any) => e.errorCode === 3)) {
                this.props.nextPage(false, "Denne tilbudskoden er allerede brukt. Ta kontakt med kundeservice om du mener dette er feil.")
            } else if (response.errors.some((e: any) => e.errorCode === 4)) {
                this.props.nextPage(false, "Det finnes allerede et aktivt abonnement på denne adressen. Vennligst ta kontakt med kundeservice.")
            } else {
                this.props.nextPage();
            }
        }

        window.scrollTo({top: 0});
    }

    getArrangementDetails() {
        if (this.props.address.projectFk.arrangementFeeProduct === null) {
            return "Etablering for din adresse er ikke definert. Ta kontakt med vårt kundesenter for detaljer. "
        }

        if (this.props.address.projectFk.isNoArrangementFee || (this.state.hasPerson && this.props.address.projectFk.existingCustomerArrangementFeeProduct.priceOnce === 0)) {
            return "Det er ingen etableringskostnad for din bestilling!";
        }

        let arrangementFee = 0;
        if (this.state.hasPerson) {
            arrangementFee = this.props.address.projectFk.existingCustomerArrangementFeeProduct.priceOnce;
        } else if (this.props.address.projectFk.arrangementFeeProduct.priceOnce !== 0) {
            arrangementFee = this.props.address.projectFk.arrangementFeeProduct.priceOnce;
        }

        return "Etableringskostnad for din bestilling vil være kr " + arrangementFee + ",-";
    }

    render() {
        return (
            <div>
                <h2 className="visuallyhidden">Bestill</h2>

                <div className="categories">
                    <h3 className="h4_tags">Valgt produkt</h3>
                    <div>
                        <ProductPart name={this.props.product.name}
                                     price={this.props.product.price}
                                     large={true}
                                     summary={true}
                                     description={this.props.product.description}
                                     discountedPrice={this.props.product.discountedPrice}
                        />
                    </div>
                </div>

                <div className="categories">
                    <h3 className="h4_tags">Etableringskostnad</h3>
                    <p>{this.getArrangementDetails()}</p>
                    <p>Produktet forutsetter bindingstid på 12 måneder fra produktets oppstartsdato.</p>
                </div>

                <div className="categories">
                    {
                        this.state.hasPerson ?
                            <p>Dersom disse opplysningene ikke stemmer vennligst ta kontakt med kundeservice.</p>
                            :
                            null
                    }

                    <div className="formPlacer">
                        <label htmlFor="firstname">Fornavn:</label>
                        <input name={"firstname"} id="firstname" placeholder={"Fornavn"} type="text"
                               className={"inputfield address " + (this.state.firstnameError ? "error" : null)}
                               onChange={this.handleChange} value={this.props.address.personFk?.name}
                               disabled={this.state.hasPerson}/>
                        {this.state.firstnameError ? <div className="errorDescription">Fornavn mangler</div> : null}

                        <label htmlFor="lastname">Etternavn:</label>
                        <input name={"lastname"} id="lastname" placeholder={"Etternavn"} type="text"
                               className={"inputfield address " + (this.state.lastnameError ? "error" : null)}
                               onChange={this.handleChange} value={this.props.address.personFk?.surname}
                               disabled={this.state.hasPerson}/>
                        {this.state.lastnameError ? <div className="errorDescription">Etternavn mangler</div> : null}

                        <label htmlFor="birthdate">Fødselsdato:</label>
                        <input name={"birthdate"} id="birthdate" placeholder={"Fødselsdato (dd.mm.åååå)"}
                               className={"inputfield address " + (this.state.bdateError ? "error" : null)}
                               onChange={this.handleChange}
                               value={this.state.hasPerson ? moment(this.props.address.personFk?.birthday).format("YYYY-MM-DD") : null}
                               disabled={this.state.hasPerson} type={"date"}/>
                        {this.state.bdateError ?
                            <div className="errorDescription">Fødselsdato mangler, eller er skrevet feil
                                (dd.mm.åååå).</div> : null}


                        <label htmlFor="phone">Mobiltelefon:</label>
                        <input name={"phone"} id="phone" placeholder={"Telefonnummer"} type="text"
                               className={"inputfield address " + (this.state.phoneError ? "error" : null)}
                               onChange={this.handleChange} value={this.props.address.personFk?.cellphone}
                               disabled={this.state.hasPerson}/>
                        {this.state.phoneError ?
                            <div className="errorDescription">Telefonnummer mangler, eller er skrevet
                                feil. </div> : null}


                        <label htmlFor="email">E-postadresse</label>
                        <input name={"email"} id="email" placeholder={"E-postadresse"} type="text"
                               className={"inputfield address " + (this.state.emailError ? "error" : null)}
                               onChange={this.handleChange} value={this.props.address.personFk?.email}
                               disabled={this.state.hasPerson}/>
                        {this.state.emailError ?
                            <div className="errorDescription">E-post mangler eller inneholder feil</div> : null}

                        <div className={"verify " + (this.state.verifyError ? "verifyError" : null)}>
                            <input id={"verify"} onChange={this.handleChange} type="checkbox"/>
                            <label htmlFor="verify"> Jeg aksepterer <a
                                rel="noopener noreferrer nofollow"
                                href="https://www.tafjord.no/tv-og-internett/avtalevilkaar-fiber/avtalevilkaar-fiber/"
                                target="_blank">vilkårene</a> for avtalen og er kjent med <a
                                rel="noopener noreferrer nofollow"
                                href="https://www.tafjord.no/media/2444/angrerett_tafjord-connect-as.pdf"
                                target="_blank">angreretten.</a></label>
                        </div>
                        {this.state.verifyError ? <div className="errorDescription">Vilkårene må aksepteres for å kunne
                            fortsette. </div> : null}

                    </div>
                </div>

                {
                    this.state.linkOrganization ?
                        ""
                        :
                        <div className="categories">
                            <h3 className="h4_tags">Vi gir 250 kr til de du heier på!</h3>
                            <p>
                                Vi er opptatt av å ta vare på lokalsamfunnet vårt. Når du bestiller fra oss gir vi
                                derfor 250
                                kroner til det lokale idrettslaget, korpset eller organisasjonen du ønsker å støtte.
                                Velg din favoritt under.
                            </p>

                            <select className="inputfield" onChange={this.setOrg}>
                                <option value="" disabled selected hidden>Jeg vil gi min støtte til:</option>
                                <option value="">Jeg ønsker ikke å støtte noen</option>
                                {this.state.organizations.map((organization, index) => {
                                    return (
                                        <option key={index} value={organization.pk}> {organization.name} </option>)
                                })}
                            </select>
                        </div>
                }

                <div className="categories">
                    <h3 className="h4_tags">Du er straks i mål!</h3>
                    <p>
                        Når du trykker på bestill vil vi sende deg en bestillingsbekreftelse på mail,
                        her får du også ytterligere informasjon om levering.
                    </p>
                    <p>    
                        Skal fakturaen sendes til en annen adresse enn leveringsadressen til abonnementet, må du ta kontakt med oss. 
                        Er det arbeidsgiver som skal motta faktura må vi ha en skriftlig bekreftelse fra bedriften på dette.
                    </p>
                    <p>
                        Har du noen spørsmål er det bare å ta kontakt på telefon
                        <a rel="noopener noreferrer nofollow" href="tel:70%2010%2000%2000"> 70 10 00 00</a>,
                        <a rel="noopener noreferrer nofollow" href="https://www.tafjord.no/kundesenter/kontakt-oss/"> kontaktskjema</a>,
                        <a rel="noopener noreferrer nofollow" href="https://www.facebook.com/tafjord.no"> facebook</a> eller via chat.
                    </p>
                </div>

                <div className="buttonplacer">
                    <div className="backbutton" onClick={() => this.props.prevPage()}>
                        <div className="arrow-left"/>
                        Tilbake
                    </div>

                    <div className="formButton" onClick={() => this.validate()}>
                        Bestill!
                    </div>
                </div>
            </div>
        );
    }

};
