import * as React from "react";
import { useEffect } from "react";

interface SummaryProps {
    result: boolean;
    message: string;
}

export function Summary(props: SummaryProps) {

    useEffect(() => {
        document.getElementById("summaryHeader")!.scrollIntoView();
    });

    return (
        <div id="summaryHeader">
            {
                props.message ?
                    ""
                    :
                    <h1 className="h1_tags">
                        Takk for bestillingen!
                    </h1>
            }
            {
                props.result ?
                    <h3 className="h3_tags">
                        Du vil motta ordrebekreftelse på e-post om noen øyeblikk!
                    </h3>
                    :
                    props.message ?
                        <h3 className="h3_tags">
                            {props.message}
                        </h3>
                        :
                        <h3 className="h3_tags">
                            Noe gikk feil under lagringen av din bestilling. Vennligst ta kontakt med vårt kundesenter
                            for å fullføre din bestilling.
                        </h3>
            }

        </div>
    )
}

