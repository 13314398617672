import * as React from "react";
import { useState } from "react";
import { ProductPart } from "./ProductPart";

import { gql } from 'apollo-boost'
import { Address } from "../Interfaces/Address";
import { Product } from "../Interfaces/Product";
import { CategoryProductList } from "../Interfaces/QueryResponse";
import { useQuery } from "@apollo/react-hooks";

export interface ProductsProps {
    address: Address;
    selectProduct: (product: Product) => void;
    prevPage: () => void;
    nextPage: (posted?: boolean) => void;
    code?: string;
}

const CATEGORY_PRODUCTS = gql`fragment CategoryProducts on CategoryProducts {
    name
    products {
        categoryFk {
            name
        }
        pk
        name
        price
        priceType
        description
        isMultiConnect
        parentProduct {
            name
            price
        }
        connectedProducts {
            edges {
                node {
                    pk
                    name
                    price
                    priceType
                }
            }
        }
    }
}`;


const GET_OFFER_CODE_CATEGORY_PRODUCTS = gql`
    query getOfferCodeProducts($code: String!) {
        categoryProducts(code: $code, categories: ["Tilbud", "Internett", "Bundlepakker", "Rabatt"]) {
            ...CategoryProducts
        }
    }
    ${CATEGORY_PRODUCTS}
`;

const GET_PROJECT_PRODUCTS = gql`
    query getProjectProducts($id: Int!){
        categoryProducts(projectId: $id, categories: ["Tilbud", "Internett", "Bundlepakker", "Rabatt"]) {
            ...CategoryProducts
        }
    }
    ${CATEGORY_PRODUCTS}
`;

function getCategoryName(name: string) {
    switch (name) {
        case "Bundlepakker":
            return "TV- og internettpakke";
        case "Internett":
            return "Internett (fiberbredbånd)";
        case "Tilbud":
            return "Kampanjetilbud";
        default:
            return name;
    }
}

export default function ProductsFunc(props: ProductsProps) {

    const [selectedProductId, setSelectedProductId] = useState(0);

    const {loading, data} = useQuery<CategoryProductList>(
        props.code === undefined ? GET_PROJECT_PRODUCTS : GET_OFFER_CODE_CATEGORY_PRODUCTS,
        {variables: {id: props.address.projectFk.pk, code: props.code}}
    );

    if (loading)
        return <p>Laster...</p>;

    const discountProducts = data.categoryProducts.find(pc => pc.name == 'Rabatt')?.products;
    const mainProducts = data!.categoryProducts.filter(pc => pc.name !== 'Rabatt').map(pc => {
        pc.products.map(product => {
            const relatedDiscountProducts = typeof discountProducts !== 'undefined' && discountProducts !== null
                ? discountProducts.filter(p => (p.connectedProducts !== null && Array.from(p.connectedProducts.edges).map(p => p.node.pk).includes(product.pk)) || product.pk === p.parentProduct?.pk)
                : [];
            const discountPrice = relatedDiscountProducts.length > 0
                ? [product.price, ...relatedDiscountProducts.map(p => p.priceType == 'MC' ? p.price : (p.price / 100) * product.price)].reduce((acc, cur) => acc + cur)
                : undefined;
            product.discountedPrice = Math.round(discountPrice);
            return product;
        });
        return pc;
    });

    return (
        <div>
            <h2 className="visuallyhidden">Velg produkt</h2>
            <div className="productListContainer">
                {
                    mainProducts.map(pc => (
                        <div className={"" + (pc.name !== 'Internett' ? "" : "smallProductSpacing")}>
                            <h3 className="h4_tags">
                                {getCategoryName(pc.name)}
                            </h3>
                            {
                                pc.products.map((product) => {
                                    return (
                                        <div key={product.pk}
                                             className={"" + (pc.name !== 'Internett' ? "" : "smallProduct")}
                                             onClick={() => {
                                                 props.selectProduct(product);
                                                 setSelectedProductId(product.pk);
                                                 props.nextPage();
                                             }}>
                                            <ProductPart name={product.name}
                                                         price={product.price}
                                                         large={true}
                                                         description={product.description}
                                                         selected={selectedProductId === product.pk}
                                                         discountedPrice={product.discountedPrice}
                                            />
                                        </div>
                                    );
                                })
                            }
                            <div className="divider"/>
                        </div>
                    ))}
            </div>
        </div>
    );
};
