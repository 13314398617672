import * as React from "react";
import { useState } from "react";
import { ApolloConsumer } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import { Matrikkel } from "../Interfaces/Matrikkel";

interface ContactFormProps {
    matrikkel: Matrikkel
    nextPage: (posted?: boolean, message?: string) => void;
}

interface ContactFormState {
    firstname: string;
    lastname: string;
    bdate: string;
    phone: string;
    email: string;
    firstnameError: boolean;
    lastnameError: boolean;
    bdateError: boolean;
    phoneError: boolean;
    emailError: boolean;
}

const SUBMIT_CONTACT_FORM_MUTATION = gql`
mutation SubmitForm($firstName: String!, $lastName: String!, $phone: String!, $email: String!, $postNr: String!, $gatenavn: String!, $husnummer: String!, $bokstav: String!, $boligNr: String!) {
  submitContactForm(firstName: $firstName, lastName: $lastName, phone: $phone, email: $email, postNr: $postNr, gatenavn: $gatenavn, husnummer: $husnummer, bokstav: $bokstav, boligNr: $boligNr) {
    status
  }
}`;

export default function ContactForm(props: ContactFormProps) {
    const [firstName, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    return (
        <ApolloConsumer>
            {(client: any) => (
                <div>
                    <p>
                        Vi kan dessverre ikke levere fiber til denne adressen for øyeblikket. Meld gjerne din interesse
                        ved å bruke skjemaet nedenfor så tar vi kontakt når fiber er tilgjengelig på din adresse.
                    </p>
                    <form onSubmit={async e => {
                        e.preventDefault();
                        const form = e.target as HTMLFormElement;

                        if (form.checkValidity()) {
                            const response = await client.mutate({
                                mutation: SUBMIT_CONTACT_FORM_MUTATION,
                                variables: {
                                    ...props.matrikkel,
                                    firstName: firstName,
                                    lastName: lastname,
                                    phone: phone,
                                    email: email
                                }
                            });

                            if (response.data.submitContactForm.status === "OK") {
                                props.nextPage(false, "Takk for din interesse! Vi tar kontakt når fiber er tilgjengelig på din adresse.")

                            } else {
                                props.nextPage(false, "Det oppstod en feil under innsending av skjemaet.")
                            }
                        }
                    }}>
                        <label htmlFor="firstname">Fornavn:</label>
                        <input type="text" id="firstname" className="inputfield w-100" placeholder="Fornavn" required
                               onBlur={e => setFirstName(e.target.value)}/>

                        <label htmlFor="lastname">Etternavn:</label>
                        <input type="text" id="lastname" className="inputfield w-100" placeholder="Etternavn" required
                               onBlur={e => setLastName(e.target.value)}/>

                        <label htmlFor="phone">Telefonnummer:</label>
                        <input type="text" id="phone" pattern="[0-9]{8}" className="inputfield w-100"
                               placeholder="Telefonnummer" required onBlur={e => setPhone(e.target.value)}
                               title="Telefonnummer uten landskode (bare 8 tall)"/>

                        <label htmlFor="email">Epost:</label>
                        <input type="email" id="email" className="inputfield w-100" placeholder="Epost" required
                               onBlur={e => setEmail(e.target.value)}/>

                        <button className="formButton btn-block mt-1">
                            Send inn
                        </button>
                    </form>
                </div>
            )}
        </ApolloConsumer>
    )
}
