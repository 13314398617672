import * as React from 'react';
import './App.css';
import TafjordForm from "./Components/TafjordForm";
import {ApolloProvider} from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';

const client = new ApolloClient({
    uri: "https://salg.tafjord.no/graphql/public/"
});

const App = () => <ApolloProvider client={client}> <TafjordForm/> </ApolloProvider>;

export default App;
