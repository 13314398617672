import * as React from 'react';
import "../CSS/ProductStyle.css";
import { Product } from "../Interfaces/Product";

interface ProductPartProps {
    summary?: boolean;
    name: string;
    price: number;
    description: string;
    large?: boolean;
    selected?: boolean;
    discountedPrice?: number;
}

export function ProductPart(props: ProductPartProps) {
    return (
        <div className={(props.summary ? "selectedProduct" : "productContainer")}>
            <div>
                <h4 className="h1_tags">{props.name}</h4>
                <h2 className="h2_tags">
                    {
                        isNaN(props.discountedPrice)
                            ? <span>{props.price},-/mnd</span>
                            : <span><s>{props.price},-/mnd</s> {props.discountedPrice},-/mnd</span>
                    }
                </h2>
            </div>
            <p dangerouslySetInnerHTML={{__html: props.description}}/>
        </div>
    )
}
