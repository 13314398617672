import * as React from "react";
import AddressForm from "./AddressFinder";
import ProductsFunc from "./Products";
import OrderSummary from "./OrderSummary";
import "../CSS/Progress.css";

import {Summary} from "./Summary";
import {Address} from "../Interfaces/Address";
import {Person} from "../Interfaces/Person";
import {Product} from "../Interfaces/Product";
import {Matrikkel} from "../Interfaces/Matrikkel";
import {QueryResponse} from "../Interfaces/QueryResponse";
import {AddressSalesAreaStatusResponse} from "../Interfaces/AddressSalesAreaStatusResponse";
import ContactForm from "./ContactForm";

interface TafjordFormProps {

}

interface TafjordFormState {
    page: number;
    code?: string;
    posted: boolean;
    customerCode?: string;
    address?: Address;
    person?: Person;
    product?: Product;
    message: string;
    selectedMatrikkel?: Matrikkel;
}

export default class TafjordForm extends React.Component<TafjordFormProps, TafjordFormState> {
    constructor(props: TafjordFormProps) {
        super(props);

        const urlParams = new URLSearchParams(window.location.search);
        const personCode = urlParams.get("for");
        const code = urlParams.get("code");

        this.state = {
            page: 0,
            posted: false,
            code: code ?? undefined,
            customerCode: personCode ?? undefined,
            address: undefined,
            person: undefined,
            product: undefined,
            message: ""
        };
    }

    async componentDidMount() {
        if (this.state.customerCode === undefined) return;

        await this.registerClick();
        await this.getAddress();
    }

    getFormStep() {
        switch (this.state.page) {
            case 0:
                return <AddressForm setAddress={this.setAddress.bind(this)}/>;
            case 1:
                return <ProductsFunc address={this.state.address as Address}
                                     selectProduct={this.setProduct.bind(this)}
                                     prevPage={this.prevPage.bind(this)}
                                     nextPage={this.nextpage.bind(this)}
                                     code={this.state.code}
                />;
            case 2:
                return <OrderSummary product={this.state.product as Product} setcustomer={this.setCustomer.bind(this)}
                                     prevPage={this.prevPage.bind(this)} address={this.state.address as Address}
                                     nextPage={this.nextpage.bind(this)} code={this.state.code}/>;
            case 3:
                return <Summary result={this.state.posted} message={this.state.message}/>;
            case 4:
                return <ContactForm matrikkel={this.state.selectedMatrikkel} nextPage={this.nextpage.bind(this)}/>;
            default:
                return null;
        }
    }

    async registerClick() {

        const query = `
            mutation RegisterClick($personCode: String!, $offerCode: String!) {
                registerClick(personCode: $personCode, offerCode: $offerCode) {
                    status
                }
            }
        `;

        await fetch('https://salg.tafjord.no/graphql/public/', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                query: query,
                variables: {
                    personCode: this.state.customerCode,
                    offerCode: this.state.code
                }
            })
        });
    }

    async getAddress() {
        const res = await fetch(`https://salgapi.tafjord.no/address/?personCode=${this.state.customerCode}`);
        const address = await res.json();
        this.setAddress(address);
    }

    nextpage(posted?: boolean, message: string = null) {
        if (this.state.page < 3) {
            if (posted) {
                this.setState({
                    page: this.state.page + 1,
                    posted: true
                });
            } else {
                this.setState({
                    page: this.state.page + 1,
                    message: message
                });
            }
        } else if (this.state.page === 4) {
            this.setState({page: 3, message: message})
        }
    }

    prevPage() {
        if (this.state.page === 4) {
            this.setState({page: 0})
        } else if (this.state.page > 0) {
            this.setState({page: this.state.page - 1});
        }
    }

    setPage(selectedPage: number) {
        if (this.state.page > selectedPage) {
            this.setState({page: selectedPage})
        }
    }

    getButtonText() {
        if (this.state.page === 2) {
            return "Bestill!";
        } else return "Neste";
    }

    async setAddress(address: Matrikkel) {

        const query = `
            query isAddressInSalesarea($zip: String!, $street: String!, $houseNumber: String!, $houseLetter: String, $unit: String!) {
              addressSalesareaStatus(zip: $zip, street: $street, houseNumber: $houseNumber, houseLetter: $houseLetter, unit: $unit) {
                matrikkel {
                  postNr
                  poststed
                  gatenavn
                  husnummer
                  bokstav
                  etasje
                  boligNr
                  gnr
                  bnr
                }
                isExistingCustomer
                isInSalesarea
                project {
                  pk
                  id
                  isNoArrangementFee
                  isNoCustomerEffort
                  arrangementFeeProduct {
                    name
                    priceOnce
                  }
                }
              }
            }
        `;

        const response = await fetch('https://salg.tafjord.no/graphql/public/', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                query: query,
                variables: {
                    zip: address.postNr,
                    street: address.gatenavn,
                    houseNumber: address.husnummer,
                    houseLetter: address.bokstav,
                    unit: address.boligNr
                }
            })
        });

        const responseJson: QueryResponse<AddressSalesAreaStatusResponse> = await response.json();

        if (!responseJson.data.addressSalesareaStatus.isInSalesarea) {
            this.setState({selectedMatrikkel: address, page: 4})
        } else {
            const selectedMatrikkel = responseJson.data.addressSalesareaStatus.matrikkel;
            const addr: Address = {
                street: `${selectedMatrikkel.gatenavn} ${selectedMatrikkel.husnummer}${selectedMatrikkel.bokstav}`,
                street_name: selectedMatrikkel.gatenavn,
                house_number: selectedMatrikkel.husnummer,
                house_letter: selectedMatrikkel.bokstav,
                unit: selectedMatrikkel.boligNr,
                city: selectedMatrikkel.poststed,
                gnr: selectedMatrikkel.gnr,
                bnr: selectedMatrikkel.bnr,
                isExistingCustomer: responseJson.data.addressSalesareaStatus.isExistingCustomer,
                postnum: parseInt(selectedMatrikkel.postNr),
                projectFk: responseJson.data.addressSalesareaStatus.project,
                id: "",
                pk: 0,
                personFk: undefined
            };
            this.setState({address: addr, page: 1})
        }
    }

    setProduct(product: Product) {
        this.setState({product: product})
    }

    setCustomer(name: string, surname: string, birthday: Date, cellphone: number, email: string) {
        const person = {
            name,
            surname,
            birthday,
            cellphone,
            email
        };

        this.setState({person})
    }

    getProgress() {
        if (this.state.page !== 3) {
            return (
                <div className="progressContainer">
                    <div className={this.state.page === 0 ? "progressSelected" : undefined}
                         onClick={() => this.setPage(0)}
                    >
                        Finn adresse
                    </div>

                    <div className={this.state.page === 1 ? "progressSelected" : undefined}
                         onClick={() => this.setPage(1)}
                    >
                        Velg produkt
                    </div>

                    <div className={this.state.page === 2 ? "progressSelected" : undefined}>
                        Bestill
                    </div>
                </div>
            )
        }
    }


    render() {
        return (
            <div className="step_container">
                {this.getProgress()}

                <div>
                    {this.getFormStep()}
                </div>

                {
                    this.state.page === 3 || this.state.page === 2 ?
                        null
                        :
                        <div>
                            {
                                this.state.page !== 0 ?
                                    <div className="backbutton mt-1" onClick={() => this.prevPage()}>
                                        <div className="arrow-left"/>
                                        Tilbake
                                    </div>
                                    :
                                    null
                            }
                        </div>
                }

            </div>
        );
    }
}
